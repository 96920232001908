.Login-bg {
  background-color: #f2f2f2;
  background-image: linear-gradient(110deg, #5878fa, #acc3fc);
  min-height: 100vh;
  position: relative;
}

.Reset-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 120px;
  margin-bottom: 120px;
  height: 620px;
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.Reset-wrapper .blue-slider {
  position: absolute;
  width: 54%;
  height: 100%;
  top: 0;
  left: -20%;
  transition: left 0.3s;
  background-color: #2d57cd;
}

.svg-container {
  position: absolute;
  z-index: 200;
  top: 0;
  right: -25%;
  height: 100%;
  width: 25%;
}

.Reset-wrapper .blue {
  width: 50%;
  border-radius: 20px 0 0 20px;
  position: relative;
  height: 100%;
  z-index: 100;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}

.Toggle-title {
  z-index: 100;
  position: relative;
}

.Reset-wrapper .blue img {
  position: relative;
  left: 34%;
  transform: translate(-50%);
  top: 50px;
}

.Reset-wrapper .white {
  width: 48%;
  padding: 70px 70px 70px 0px;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  position: relative;
}

.Reset-wrapper .white .Form-content {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 86%;
  text-align: center;
}

.Form-content a.Forgot-password {
  font-size: 14px;
  float: right;
  margin-bottom: 12px;
  margin-top: -8px;
}

.Reset-wrapper .blue .Toggle-title div,
.Reset-wrapper .blue .Form-title div {
  color: #ffffff;
}

.Reset-wrapper .Toggle-title div {
  font-size: 28px;
  font-weight: 700;
}

.Reset-wrapper .white .Form-title {
  color: #435177;
  margin-bottom: 20px;
}

.Reset-wrapper .blue .Form-title {
  margin-top: 30px;
  margin-bottom: 50px;
}

.Reset-wrapper .Form-content .Form-title div:first-child {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}

.Reset-wrapper .Form-content .Form-title div:last-child {
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 50px;
}

.Bg-animation {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
}

.svg-curve {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -1px;
}

svg {
  overflow: visible;
}
.s-path {
  transition: 0.2s;
}

.Reset-wrapper .white .Toggle-title,
.Reset-wrapper.switch .blue .Toggle-title {
  opacity: 0;
  transition: opacity 0.2s;
}

.Reset-wrapper .blue .Toggle-title {
  position: absolute;
  top: 50%;
  left: 33%;
  padding: 0 20px;
  transform: translate(-50%, -50%);
}

.Toggle-title {
  position: absolute;
}

.Reset-wrapper .blue .Form-content {
  opacity: 0;
  transition: opacity 0.2s;
}

.blue .Toggle-title {
  position: absolute;
}

.Bg-animation canvas {
  position: absolute;
}

.Reset-wrapper .Form-content .Link-btn {
  margin-top: 60px;
}

.Reset-wrapper .Form-content .Link-btn span {
  font-size: 14px;
  color: #8191ba;
}

@media (max-width: 1400px) {
  .Reset-wrapper-container {
    padding: 15px 80px;
  }

  .Reset-wrapper {
    margin-top: 45px;
    margin-bottom: 45px;
    height: 550px;
  }

  .Reset-wrapper .blue img {
    height: 46px;
  }
}

@media (max-width: 991px) {
  .Reset-wrapper-container {
    padding: 15px 0;
  }
}

@media (max-width: 767px) {
  .Reset-wrapper-container {
    padding: 15px;
  }

  .Reset-wrapper .blue-slider {
    display: none;
  }

  .Reset-wrapper .blue {
    display: none;
  }

  .Reset-wrapper .white {
    width: 100%;
    padding: 60px 20px;
  }

  .Reset-wrapper .white .Form-content {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0);
    width: 100%;
    text-align: center;
  }
}
