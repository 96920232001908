.Header {
    background-color: white;
    padding: 0 30px 0 0;
    margin-left: 64px;
    min-height: 70px;
    width: calc(100% - 64px);
    position: absolute;
    -webkit-box-shadow: 0 0 3px rgba(31,30,47,.05);
    box-shadow: 0 0 3px rgba(31,30,47,.05);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Header-link {
    max-height: 70px;
}

.Header-link span {
    font-size: 13px;
    color: #828db1;
}

.Header-link i:before {
    color: #828db1;
    font-size: 11px;
    transform: rotate(-90deg);
    margin-left: 10px;
    transition: transform 0.15s;
}

.Header-link .show.dropdown i:before {
    transform: rotate(90deg);
}

.Header .Account-avatar img {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    margin-right: 12px;
    margin-top: -4px;
}

.dropdown-toggle::after {
    display: none;
}

.show.dropdown button,
.show.dropdown button.btn:focus,
.show.dropdown button.btn.focus {
    box-shadow: none;
}

.dropdown-item {
    color: #212529;
    font-size: 13px;
}
