.Indicator-arrow {
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right:9px solid #EAF0F7;
    position: absolute;
    right: 0;
}


.Sidebar-menu img {
    margin-top: 20px;
    margin-bottom: 16px;
}

.Sidebar-menu {
    width: 64px;
    background-color: #0F3B55;
    height: 100vh;
    position: fixed;

}

.Sidebar-menu i {
    font-size: 19px;
    color: #E9EFFD;

}

.Sidebar-menu .Link {
    width: 100%;
    height: 60px;
    justify-content: center;
}

.Sidebar-menu .Link.active i{
    color: #3FB2FF;
}


.Sidebar-menu i:hover,
.Sidebar-menu i:focus,
.Sidebar-menu i.active {
    color: #3FB2FF;
}

.Link-account {
    margin-bottom: 50px;

}

.Sidebar .Account-avatar,
.Sidebar-popover .Account-avatar{
    border-radius: 100%;
    background: white;
    background-image: url("../../_images/user-circle.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    text-align: center;
    width: 36px;
    height: 36px;
}



.tooltip-inner {
    background-color: white;
    color: #50649C;
    font-weight: 500;
}

.bs-tooltip-right .arrow::before {
    border-right-color: #E9EFFD;
}

.Sidebar-popover .popover-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
}


.Account-row span {
    margin-left: 10px;
    font-weight: 600;
}

.Sidebar-popover .popover-body div {
    border-bottom: #f2f2f2 solid 1px;
    padding: 16px 16px ;

}

.Sidebar-popover .popover-body .Account-row {
    height: 60px;
}

.Sidebar-popover .popover-body .Account-row .Account-avatar {
    width: 36px;
    height: 36px;
}

.Sidebar-popover .popover-body .Link-row {
    height: 50px;
    width: 100%;
}

.Sidebar-popover .popover-body a {
    color: #333333;
    text-decoration: none;
}

.Sidebar-popover .popover-body a:hover,
.Sidebar-popover .popover-body a:active {
    color: #2EC5D2;
}

.Sidebar-popover .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.Sidebar-popover {
    margin-left: 0.5rem;
}

.Sidebar-popover .arrow::after {
    left: 2px;
    border-right-color: #ffffff;
}

.Sidebar-popover .arrow::before{
    left: 0;
    border-right-color: #00000040;
}

.Sidebar-popover .arrow::before,
.Sidebar-popover .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-style: solid;
}
