.Login-bg {
  background-image: linear-gradient(110deg, #5878fa, #acc3fc);
  min-height: 100vh;
  position: relative;
}

.Login-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 120px;
  margin-bottom: 120px;
  height: 620px;
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.Login-wrapper .blue-slider {
  position: absolute;
  width: 54%;
  height: 100%;
  top: 0;
  left: -21%;
  will-change: left;
  transition: left 0.3s ease-out;
  background-color: #2d57cd;
}

.Login-wrapper.switch .blue-slider {
  left: 0;
}

.svg-container {
  position: absolute;
  z-index: 200;
  top: 0;
  right: -25%;
  height: 100%;
  width: 25%;
}

.Login-wrapper .blue {
  width: 50%;
  border-radius: 20px 0 0 20px;
  position: relative;
  height: 100%;
  z-index: 100;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.Login-wrapper.switch .blue {
  z-index: 300;
  transform: translateX(50px);
}

.Login-wrapper .Form-content {
  position: absolute;
  left: 50%;
  top: 50%;
  width: calc(100% - 140px);
  transform: translate(-50%, -50%);
}

.Login-wrapper .blue .Form-content {
  opacity: 0;
  pointer-events: none;
}

.Login-wrapper.switch .blue .Form-content {
  opacity: 1;
  transition: opacity 0.2s;
  pointer-events: all;
}

.Toggle-title {
  z-index: 100;
  position: relative;
}

.Login-wrapper .blue img {
  position: absolute;
  left: 33%;
  transform: translate(-50%);
  top: 50px;
}

.Login-wrapper.switch .blue img {
  opacity: 0;
}

.Login-wrapper .white {
  position: relative;
  width: 50%;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transform: translateX(-50px);
}

.Login-wrapper.switch .white {
  transform: translateX(0);
}

.Login-wrapper .white .Form-content {
  transition: opacity 0.25s;
}

.Login-wrapper.switch .white .Form-content {
  opacity: 0;
  pointer-events: none;
  transition: none;
}

.Form-content a.Forgot-password {
  font-size: 14px;
  float: right;
  margin-bottom: 12px;
  margin-top: -8px;
}

.Login-wrapper .blue .Toggle-title div,
.Login-wrapper .blue .Form-title div {
  color: #ffffff;
}

.Login-wrapper .Toggle-title div:first-child {
  font-size: 14px;
}

.Login-wrapper .Toggle-title div:last-child {
  font-size: 28px;
  font-weight: 700;
}

.Login-wrapper .Form-title {
  margin-bottom: 40px;
}

.Login-wrapper.switch .blue {
  text-align: center;
}

.Login-wrapper .Form-content {
  color: #435177;
}

.Login-wrapper .Form-content .Form-title div:first-child {
  font-size: 28px;
  font-weight: 700;
}

.Login-wrapper .Form-content .Form-title div:last-child {
  font-size: 14px;
  margin-top: 6px;
}

.Btn-arrow {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  transition: left 0.2s, width 0.2s, height 0.2s;
  z-index: 1000;
  cursor: pointer;
  will-change: left;
}

.switch .Btn-arrow {
  left: 100%;
}

.Btn-arrow:hover {
  width: 80px;
  height: 80px;
}

.Btn-arrow-img {
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
}

.switch .Btn-arrow-img {
  transform: rotate(0deg);
}

.Bg-animation {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
}

.svg-curve {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-left: -1px;
}

svg {
  overflow: visible;
}

.s-path {
  transition: 0.2s;
}

.Login-wrapper.switch .blue .Toggle-title {
  position: absolute;
  opacity: 0;
  transition: unset;
  pointer-events: none;
}

.Login-wrapper .white .Toggle-title {
  position: absolute;
  top: 50%;
  right: 32%;
  opacity: 0;
  transition: unset;
  pointer-events: none;
  color: #435177;
  transform: translate(50%, -50%);
}

.Login-wrapper.switch .white .Toggle-title {
  opacity: 1;
  pointer-events: auto;
}

.Login-wrapper .blue .Toggle-title {
  position: absolute;
  top: 50%;
  left: 32%;
  padding: 0 20px;
  transform: translate(-50%, -50%);
}

.Login-wrapper.switch .blue .Toggle-title {
  opacity: 0;
}

.Login-wrapper .Toggle-title div:last-child:hover {
  text-decoration: underline;
  cursor: pointer;
}

.Bg-animation canvas {
  position: absolute;
}

.Form-content .alert.alert-danger {
  min-height: 21px;
}

@media (max-width: 1400px) {
  .Login-wrapper-container {
    padding: 15px 80px;
  }

  .Login-wrapper {
    margin-top: 45px;
    margin-bottom: 45px;
    height: 550px;
  }

  .Login-wrapper .blue img {
    height: 46px;
  }

  .Login-wrapper.switch .blue {
    padding: 60px 40px;
  }
}

@media (max-width: 1200px) {
  .Login-wrapper .Form-content {
    width: calc(100% - 60px);
  }

  .Login-wrapper.switch .blue {
    transform: translateX(40px);
  }

  .Login-wrapper .white {
    transform: translateX(-40px);
  }
}

@media (max-width: 991px) {
  .Login-wrapper-container {
    padding: 15px;
  }

  .svg-container {
    right: -20%;
    width: 20%;
  }
}
