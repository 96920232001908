.FormFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 24px;
}

.FormFooter span {
  font-size: 12px;
  color: #50649c;
  cursor: pointer;
}

.FormFooter div span:first-child {
  font-size: 12px;
  margin-left: 0;
  font-weight: normal;
}

.FormFooter .icon-ic_arrow {
  vertical-align: text-bottom;
}

.FormFooter .icon-ic_arrow:before {
  font-size: 8px;
  transform: rotate(-90deg);
  transition: transform 0.3s;
}

.FormFooter--light span {
  color: white;
}

.FormFooter--light .icon-ic_arrow:before {
  color: white;
}

.FormFooter-dropdown {
  position: relative;
  user-select: none;
  text-align: left;
}

.FormFooter-dropdown--open .icon-ic_arrow:before {
  transform: rotate(90deg);
}

.FormFooter-dropdown-items {
  position: absolute;
  left: 0;
}
