body {
  background-color: #eaf0f7;
}

.AppWrapper .container-fluid {
  padding: 0;
}
.AppWrapper-content {
  padding: 0 30px 0 30px;
  margin-left: 64px;
  margin-top: 70px;
}

.Header-title {
  font-size: 20px;
  color: #50649c;
  font-weight: 600;
  padding: 25px 0;
}

.Card {
  margin-bottom: 24px;
  border: none;
  box-shadow: 0 0 3px rgba(31, 30, 47, 0.05);
  border-radius: 3px;
  background-color: #fff;
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.Card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.Card-title {
  margin-bottom: 26px;
  text-transform: capitalize;
  letter-spacing: 0.02em;
  font-size: 16px;
  font-weight: 600;
  color: #50649c;
}

.Account-avatar {
  position: relative;
}

.Account-avatar img {
  border-radius: 100%;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.Accont-avatar--loading img {
  opacity: 0.2;
}

.Avatar-loader {
  position: absolute;
  top: 10px;
  left: 10px;
}

.Avatar-upload-form {
  display: none;
}

.form-group {
  margin-bottom: 4px;
}

.col-form-label {
  text-align: right;
  padding-top: calc(0.375rem + 3px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 13px;
  font-weight: 400;
  color: #656d9a;
}

.AppWrapper-content .form-control {
  font-size: 13px;
  border: 1px solid #e8ebf3;
  height: calc(2.3rem + 2px);
  color: #2f5275;
  border-radius: 0.25rem;
}

.AppWrapper-content .form-control:focus {
  border-color: rgba(77, 121, 246, 0.5);
  box-shadow: none;
}

.Profile-picture {
  margin-bottom: 16px;
}

.Profile-picture label {
  padding-top: 20px;
}

.react-phone-number-input__input {
  border-bottom: none;
}

.react-phone-number-input__icon img {
  vertical-align: top;
}

.react-phone-number-input__country-select-arrow {
  color: #2f5275;
}
