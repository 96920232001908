.LoginRegisterMobile {
  border-radius: 20px;
  position: relative;
  margin: 50px 0;
  background: #ffffff;
  height: 620px;
}

.LoginRegisterMobile-inner {
  border-radius: 20px;
  position: relative;
  padding: 60px 20px;
  height: 100%;
  overflow: hidden;
}

.LoginRegisterMobile .Form-content .Form-title div:first-child {
  font-size: 28px;
  font-weight: 700;
}

.LoginRegisterMobile .Form-content .Form-title div:last-child {
  font-size: 14px;
  margin-top: 6px;
}

.LoginRegisterMobile .Form-title {
  color: #435177;
  margin-bottom: 40px;
}
.LoginRegisterMobile .Toggle-title {
  position: relative;
  color: #435177;
  margin-top: 40px;
  margin-bottom: 20px;
}

.LoginRegisterMobile .Toggle-title div:first-child {
  font-size: 14px;
}

.LoginRegisterMobile .Toggle-title div:last-child {
  font-size: 28px;
  font-weight: 700;
}

.LoginRegisterMobile .Register {
  padding: 50px 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
  background-color: #2d57cd;
  height: 100%;
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
}

.LoginRegisterMobile.switch .Register {
  transform: translateY(0);
}

.LoginRegisterMobile .Register .Toggle-title div,
.LoginRegisterMobile .Register .Form-title div {
  color: #ffffff;
}

/*.LoginRegisterMobile .Btn-arrow {*/
  /*position: absolute;*/
  /*top: 100%;*/
  /*left: 50%;*/
  /*width: 60px;*/
  /*height: 60px;*/
  /*border-radius: 100%;*/
  /*transform: translateX(-50%) translateY(-50%);*/
  /*transition: top 0.3s ease-out;*/
  /*z-index: 1000;*/
  /*cursor: pointer;*/
/*}*/

.LoginRegisterMobile .Btn-arrow {
display: none;
}

.LoginRegisterMobile .Btn-arrow-img {
  transform: rotate(90deg);
}

.LoginRegisterMobile.switch .Btn-arrow {
  top: 0;
}

.LoginRegisterMobile.switch .Btn-arrow-img {
  transform: rotate(-90deg);
}

.LoginRegisterMobile-inner .Toggle-title div:last-child {
  font-size: 14px;
}

.LoginRegisterMobile .Toggle-title {
  cursor: pointer;
}

.LoginRegisterMobile-inner .Toggle-title div:last-child:hover {
  text-decoration: underline;
}



