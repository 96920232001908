body {
  margin: 0;
  font-family: "Open Sans";
}

.form-control {
  border-radius: 100px;
  height: 42px;
  border: 1px solid #e8ebf3;
  margin-bottom: 16px;
  padding: 5px 16px;
}

.form-control:focus {
  border-color: rgba(77, 121, 246, 0.5);
}

.form-control::placeholder {
  color: #8191ba;
  padding: 5px 0;
}

.btn {
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  height: 42px;
  display: inline-block;
}

.btn.full-width {
  width: 100%;
}

/*BTN PRIMARY*/

.Btn-primary {
  background-color: #4d79f6;
  border: 1px solid #4d79f6;
  -webkit-box-shadow: 0 2px 6px 0 rgba(77, 121, 246, 0.5);
  box-shadow: 0 2px 6px 0 rgba(77, 121, 246, 0.5);
  color: #ffffff;
}

.Btn-primary:hover,
.Btn-primary:active,
.Btn-primary:focus {
  background-color: #3567f5;
  border: 1px solid #3567f5;
  color: #ffffff;
}

.Btn-primary:focus {
  box-shadow: 0 0 0 2px rgba(77, 121, 246, 0.3);
}

.Btn-primary:disabled,
.Btn-primary.disabled {
  background-color: #3567f5 !important;
  border: 1px solid #3567f5;
  cursor: default;
}

.Btn-primary:focus {
  outline: none;
}

/*BTN-SUCCESS*/

.Btn-success {
  background-color: #1ecab8;
  border: 1px solid #1ecab8;
  -webkit-box-shadow: 0 2px 6px 0 rgba(30, 202, 184, 0.5);
  box-shadow: 0 2px 6px 0 rgba(30, 202, 184, 0.5);
  color: #ffffff;
}

.Btn-success:hover,
.Btn-success:active,
.Btn-success:focus {
  background-color: #1bb4a4;
  border: 1px solid #1bb4a4;
  color: #ffffff;
}

.Btn-success:focus {
  box-shadow: 0 0 0 2px rgba(30, 202, 184, 0.3);
}

.Btn-success:disabled,
.Btn-success.disabled {
  background-color: #1bb4a4;
  border: 1px solid #1bb4a4;
  cursor: default;
}

.Btn-success:focus {
  outline: none;
}

/*---*/

/*BTN-DANGER*/

.Btn-danger {
  color: #ffffff;
  background-color: #f1646c;
  border: 1px solid #f1646c;
  box-shadow: 0 2px 6px 0 rgba(241, 100, 108, 0.5);
}

.Btn-danger:hover,
.Btn-danger:active,
.Btn-danger:focus {
  background-color: #ef4d56;
  border: 1px solid #ef4d56;
}

.Btn-danger:focus {
  box-shadow: 0 0 0 2px rgba(241, 100, 108, 0.3);
}

.Btn-danger:disabled,
.Btn-danger.disabled {
  background-color: #ef4d56;
  border: 1px solid #ef4d56;
  cursor: default;
}

.Btn-danger:focus {
  outline: none;
}

/*---*/

.Btn-round {
  border-radius: 30px;
}

a {
  color: #4d79f6;
  font-size: 14px;
}

a:hover,
a:active,
a:focus {
  color: #3567f5;
}

a:disabled,
a.disabled {
  color: #3567f5;
  cursor: default;
}

.alert-danger {
  width: 100%;
  margin: -12px 0 8px 8px;
  font-size: 80%;
  color: #f3868d;
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  text-align: left;
}

.alert-message {
  width: 100%;
  margin: 20px 0;
  font-size: 80%;
  border-color: transparent;
  text-align: left;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 2rem;
  font-weight: 600;
}

.alert-message.alert-message-success {
  background-color: #dcf7f4;
  color: #3fcab8;
}

.alert-message.alert-message-danger {
  background-color: #fdeef5;
  color: #f3868d;
}

.alert-message i:before {
  font-size: 16px;
  margin-right: 10px;
}

.Login-wrapper.switch .alert-danger {
  color: #f1a9ad;
}

.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.Loader-overlay {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}

input::placeholder,
.form-control::placeholder {
  color: #8191ba;
}

input::-webkit-input-placeholder {
  line-height: 1 !important;
}

.logo {
  height: 60px;
}

@media (max-width: 991px) {
  .logo {
    height: 30px;
  }
}
